import React, { Suspense, useEffect, useState } from 'react'
import './App.scss'
import LazyLoad from 'react-lazyload'
import Smooth from 'react-smooth'
import links from './JSON/links.json'
import groups from './JSON/groups.json'
import productSlides from './JSON/productSlides.json'
import promoSlides from './JSON/promoSlides.json'
import feedbacks from './JSON/feedbacks.json'
import footerLinks from './JSON/footerLinks.json'
import SmoothImage from './components/SmoothImage'

const Group = React.lazy(() => import('./components/Group'))
const EmblaCarousel = React.lazy(() => import('./components/EmblaCarousel'))
const Feedback = React.lazy(() => import('./components/Feedback'))
const Photocard = React.lazy(() => import('./components/Photocard'))
const Footer = React.lazy(() => import('./components/Footer'))

function App () {
  const [screenHeight, setScreenHeight] = useState(window.screen.height)

  // Вычисляем высоту экрана и создаём CSS переменную с этим значением.
  // Значение переменной обновляется при изменеии размера экрана (не вьюпорта).
  useEffect(() => {
    const handleWindowResize = () => setScreenHeight(window.screen.height)

    document.documentElement.style.setProperty('--screen-height', `${screenHeight}px`)
    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [screenHeight])

  return (
      <div className='App'>

        <Smooth from='0' to='1' attributeName='opacity' easing='ease-in-out' duration={1500} >

          <div className='bg'></div>

          <main>

            <div className='screen' id='screen-1'>

              <div className='screen__bg-scene'>
                <div className='bg-scene__composition'>

                  <SmoothImage className='composition__item'
                    src='img/dunk@1x-min.png' alt=''
                    width='344' height='155'
                  />

                  <SmoothImage className='composition__item'
                    src='img/jordan@1x-min.png' alt=''
                    width='348' height='170'
                  />

                </div>
              </div>

              <SmoothImage
                className='screen__emoji-img'
                src='img/emoji/victory-hand.png'
                alt='✌'
                width='524'
                height='824'
              />

              <br/>

              <h1><center>Салам!<br/>Мы – <abbr title="/fa/ sales — fashion sales">Фасейлс!</abbr></center></h1>

              <br/>

              <p className='text-lt'>
                Ежедневно мы находим <em>
                  <span className='text-md'>скидоны на пиздатую одежду и обувь</span> в официальных <span title='магазинах'>магазах</span>
                </em> и делимся ими с любимыми подписчиками &lt;3
              </p>

              <br/><br/>

              <p className='down-pointer'>👇👇👇</p>

            </div>

            <div className='screen' id='screen-2'>

              <div className='screen__bg-scene'>
                  <div className='bg-scene__composition'>

                    <SmoothImage className='composition__item'
                      src='img/perfume@1x-min.png' alt=''
                      width='188' height='260'
                    />

                    <SmoothImage className='composition__item'
                      src='img/palette@1x-min.png' alt=''
                      width='236' height='195'
                    />

                  </div>
              </div>

              <h1>У нас есть четыре группы</h1>

              <br/><br/>

              <Suspense fallback={<></>}>
                <LazyLoad once offset={screenHeight}>
                  <Group groupList={groups} />
                </LazyLoad>
              </Suspense>

              <br/><br/><hr/>

              <p className='text-lt'>
                Каждая из них есть в
                <br/>
                <a className='text-md' href={links.fasales.links}>Telegram</a>
                &nbsp;и&nbsp;
                <a className='text-md' href={links.fasales.links}>VK</a>
              </p>

              <br/>

              <aside>Читайте там, где удобно</aside>

              <br/>

            </div>

            <div className='screen' id='screen-3'>

              <h1 className='text-lt'><span className='text-md'>Примеры товаров</span>, которые мы публикуем</h1>

              <br/>

              <Suspense fallback={<></>} >
                <LazyLoad className='slider products-slider' once offset={screenHeight / 1.5}>
                  <EmblaCarousel
                    slideVariant={'product'} dots={true}
                    scaleFactor={1.5} slides={productSlides} />
                </LazyLoad>
              </Suspense>

            </div>

            <div className='screen' id='screen-4'>

              <h1>Промокоды и акции</h1>

              <br/>

              <p className='text-lt'>
                У нас есть <a className='text-md' href={links.fasales.promo} target='_blank' rel='noreferrer'>сайтик</a>,
                где собраны все актуальные промокоды на самые разные магазины, зацените!
              </p>

              <br/>

              <Suspense fallback={<></>} >
                <LazyLoad className='slider promo-slider'
                  once offset={screenHeight / 1.25}>
                  <EmblaCarousel slideVariant={'promo'} dots={true}
                    scaleFactor={1} slides={promoSlides} />
                </LazyLoad>
              </Suspense>

            </div>

            <div className='screen' id='screen-5'>
              <h1 className='text-lt'>
                <span className='text-md'>А вот, что о нас говорят</span> любимые подписчики
              </h1>

              <br/><br/>

              <Suspense fallback={<></>}>
                <Feedback feedbacks={feedbacks} lazyOffset={screenHeight / 1.25} />
              </Suspense>

              <br/><br/>

              <center><aside className='text-md text-lg'>Шикарно, не правда ли? 😏</aside></center>

              <br/><br/>

            </div>

            <div className='screen' id='screen-6'>

              <h1>Если вы до сих пор сомневаетесь</h1>

              <br/>

              <p className='text-lt'>
                У нашего админа есть <a href={links.diorgloryboy.instagram}
                  target='_blank' rel='noreferrer'>инстаграмчик</a>,
                где он ежедневно рассказывает различные  лайфхаки: начиная с
                того, где купить айфон подешевле, заканчивая тем, как выехать
                в Европу во времена ковида 🧐
                <br/><br/>
                А ещё он публикует инфу о распродажах и ищет товары по запросам
                любимых подписчиков.
                <br/>И всё это совсем бесплатно ^__^
              </p>

              <br/><br/>

              <Suspense fallback={<></>} >
                <LazyLoad once offset={screenHeight}>
                  <Photocard
                    link={links.diorgloryboy.instagram}
                    sign={<>Instagram:<br/>@diorgloryboy</>}
                    imgSrc='img/diorgloryboy-min.png' imgAlt='diorgloryboy' />
                </LazyLoad>
              </Suspense>

              <br/><br/><br/><br/>

              <LazyLoad once offset={screenHeight / 1.5} >
                <div className='character-replica-container'>

                  <p className='character-replica-container__text'>
                    Короче, братва, всех ждём в наших социальных сетях
                  </p>

                  <SmoothImage className='character-replica-container__img'
                    src='img/anime-character-min.png' alt='' height='414' width='382' />

                </div>
              </LazyLoad>

            </div>

          </main>

          <Suspense fallback={<></>} >
            <LazyLoad once offset={screenHeight / 1.5}>
              <Footer links={footerLinks} />
            </LazyLoad>
          </Suspense>

        </Smooth>

      </div>
  )
}

export default App
