import React, { useState } from 'react'

const SmoothImage = ({ className, classNameOnLoad, src, alt, height, width }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const classNameOnLoadStr = isLoaded
    ? classNameOnLoad || className + '--onload'
    : ''
  return (
    <img
      className={`${className} ${classNameOnLoadStr}`}
      src={src}
      alt={alt}
      height={height}
      width={width}
      onLoad={() => setIsLoaded(true)}
    />
  )
}

export default SmoothImage
