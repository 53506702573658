import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import addMetaTag from './utils/addMetaTag'
import { version } from './version'

const nodeEnv = process.env.NODE_ENV

Sentry.init({
  environment: nodeEnv,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

if (nodeEnv === 'production') addMetaTag('version', version)

ReactDOM.render(
  <StrictMode><App /></StrictMode>,
  document.getElementById('root')
)
